import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Banner from 'components/Banner';
import { BANNER } from 'components/Banner/models.d';

import './HistoryPage.scss';

const HistoryPage: FC<{ data: HistoryPageTypes.HistoryPageSiteTypes }> = ({
  data: {
    historyDetails: {
      urls,
      seo,
      banner,
      historyTitle,
      historyText,
      image,
      principleText,
      pageName,
    },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner banner={banner} variant={BANNER.VARIANT.PRIMARY} />
      <Container fluid className="history-page">
        <Container noPadding>
          <div className="history-page__text-container">
            <div className="history-page__left-panel">
              <Typography variant="heading2" weight="medium" customClass="history-page__title">
                {historyTitle}
              </Typography>
              <Typography
                variant="paragraph5"
                weight="regular"
                customClass="history-page__text"
                dangerouslySetInnerHTML={historyText}
              />
            </div>
            <Image imageData={image} alt={image.altText} className="history-page__image" />
          </div>
          <Typography
            variant="paragraph1"
            weight="regular"
            dangerouslySetInnerHTML={principleText}
            customClass="history-page__principle"
          />
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query HistoryPageQuery($lang: String) {
    historyDetails(lang: { eq: $lang }) {
      ...HistoryPageFragment
    }
  }
`;

export default HistoryPage;
